// Components
import { deobfuscateStringValue } from "@scripts/helpers/documentEmbedding";

// Confirmation Document Page module
class ConfirmationDocumentPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initDocumentEmbeddingOnClick();
        this.initAdobeDc();
    }

    initDocumentEmbeddingOnClick() {
        const element = document.querySelector('.js-document-embedding-click');
        if (element) {
            element.addEventListener('click', () => {
                this.previewFile();
            });
        }
    }

    initAdobeDc() {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
            document.getElementById("view-pdf-btn").disabled = false;
        });
    }

    previewFile() {
        const adobeDcElement = document.getElementById("view-pdf-btn");

        if (adobeDcElement) {
            const dl = deobfuscateStringValue(adobeDcElement.dataset.dl);
            const dak = deobfuscateStringValue(adobeDcElement.dataset.dak);
            const adobeDCView = new AdobeDC.View({ clientId: dak });

            adobeDCView.previewFile(
                {
                    content: { location: { url: dl } },
                    metaData: { fileName: adobeDcElement.dataset.dfn }
                },
                {
                    embedMode: "LIGHT_BOX",
                    showDownloadPDF: true,
                    showThumbnails: false,
                    showBookmarks: false,
                    showAnnotationTools: false,
                    showPrintPDF: true
                }
            );
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-confirmation-document-page");
    if (el) new ConfirmationDocumentPage(el);
});