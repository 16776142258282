// Components
import dom from "@scripts/helpers/dom";
import Overlay from "@scripts/components/overlay";
import ValueFormatInput from "@scripts/components/valueformat-input";

// Recipient authentication page module
class RecipientAuthenticationPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        this.initRegexInputs();
        this.initChallengeFieldOnChange();

        dom.inputs.focusFirst();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-rec-auth-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                // Allows form submission some time to be cancelled
                // due to validation
                setTimeout(() => {
                    var validationResult = (form.getAttribute("data-validated") || "").toLowerCase();

                    if (validationResult === 'true') {
                        this.formOverlay.show();
                    }
                });
            });
        }
    }

    initRegexInputs() {
        let inputs = [...document.querySelectorAll(".js-valueformat-input")];
        inputs.forEach(input => {
            var format = input.getAttribute("data-valueformat");
            new ValueFormatInput(input, {
                format: format,
                placeholders: false,
                initMaskOnStartup: false
            });
        });
    }

    initChallengeFieldOnChange() {
        const element = document.querySelector('.js-challenge-text');
        if (element) {
            element.addEventListener('change', () => {
                element.className = element.options[element.selectedIndex].className;
            });
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-rec-auth-page");
    if (el) new RecipientAuthenticationPage(el);
});