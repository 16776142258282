// Components
import Overlay from "@scripts/components/overlay";
import FundingDestinationsList from "@scripts/components/funding-destinations-list";

// Funding destinations page module
class FundingDestinationsPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initFundingOptionsList();
        this.initFundingOptionsForm();
    }

    initFundingOptionsList() {
        const fundingDestinationsListEl = document.querySelector(".js-funding-destinations-list");
        this.fundingDestinationsList = new FundingDestinationsList(fundingDestinationsListEl);
    }

    initFundingOptionsForm() {
        const formOverlay = new Overlay({ target: this.el });
        const fundingForm = document.querySelector(".js-funding-destinations-form");

        fundingForm.addEventListener("submit", (e) => {
            var isValid = this.fundingDestinationsList.validate();

            if (!isValid) {
                e.preventDefault();
                return false;
            }

            formOverlay.show();
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-fd-page");
    if (el) new FundingDestinationsPage(el);
});