/*
 * Extend Helper -
 *
 * Deep extend/merge function ported from jQuery
 * Attribution: Original source: jQuery - https://github.com/jquery/jquery/blob/master/src/core.js
 */

const extendHelpers = {
    hasOwn: function (class2Type) {
        return class2Type.hasOwnProperty;
    },

    fnToString: function() {
        return extendHelpers.hasOwn.toString;
    },

    ObjectFunctionString: function() {
        return extendHelpers.fnToString.call(Object);
    },

    getProto: function (obj) {
        const types = {
            "[object Boolean]": "boolean",
            "[object Number]": "number",
            "[object String]": "string",
            "[object Function]": "function",
            "[object Array]": "array",
            "[object Date]": "date",
            "[object RegExp]": "regexp",
            "[object Object]": "object"
        };

        return obj == null ? String(obj) : types[toString.call(obj)] || "object";
    },

    isPlainObject: function (obj) {
        let proto, Ctor;

        // Detect obvious negatives
        // Use toString instead of jQuery.type to catch host objects
        if (!obj || toString.call(obj) !== "[object Object]") {
            return false;
        }

        proto = extendHelpers.getProto(obj);

        // Objects with no prototype (e.g., `Object.create( null )`) are plain
        if (!proto) {
            return true;
        }

        // Objects with prototype are plain iff they were constructed by a global Object function
        Ctor = extendHelpers.hasOwn.call(proto, "constructor") && proto.constructor;
        return typeof Ctor === "function" && extendHelpers.fnToString.call(Ctor) === extendHelpers.ObjectFunctionString;
    }
}

function extend() {
    let options, name, src, copy, copyIsArray, clone,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length,
        deep = false;

    // Handle a deep copy situation
    if (typeof target === "boolean") {
        deep = target;

        // Skip the boolean and the target
        target = arguments[i] || {};
        i++;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !extendHelpers.isFunction(target)) {
        target = {};
    }

    // Extend jQuery itself if only one argument is passed
    if (i === length) {
        throw "Requires a target to extend.";
    }

    for (; i < length; i++) {

        // Only deal with non-null/undefined values
        if ((options = arguments[i]) != null) {

            // Extend the base object
            for (name in options) {
                src = target[name];
                copy = options[name];

                // Prevent never-ending loop
                if (target === copy) {
                    continue;
                }

                // Recurse if we're merging plain objects or arrays
                if (deep && copy && (extendHelpers.isPlainObject(copy) ||
                    (copyIsArray = Array.isArray(copy)))) {

                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && Array.isArray(src) ? src : [];

                    } else {
                        clone = src && extendHelpers.isPlainObject(src) ? src : {};
                    }

                    // Never move original objects, clone them
                    target[name] = extend(deep, clone, copy);

                    // Don't bring in undefined values
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }

    // Return the modified object
    return target;
}

module.exports = extend;