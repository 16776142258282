// Components
import Overlay from "@scripts/components/overlay";

// -- TODO: Extract generic radio-list component from funding-destinations-list and refactor usages
import ApproverConsentList from "@scripts/components/funding-destinations-list";

// Funding destinations page module
class ApproverConsentPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initApproverConsentOptionsList();
        this.initApproverConsentOptionsForm();
    }

    initApproverConsentOptionsList() {
        const listEl = document.querySelector(".js-approver-consent-list");
        this.approverConsentList = new ApproverConsentList(listEl);
    }

    initApproverConsentOptionsForm() {
        const formOverlay = new Overlay({ target: this.el });
        const form = document.querySelector(".js-approver-consent-form");

        form.addEventListener("submit", (e) => {
            var isValid = this.approverConsentList.validate();
            
            if (!isValid) {
                e.preventDefault();
                return false;
            }

            formOverlay.show();
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-approver-consent-page");
    if (el) new ApproverConsentPage(el);
});