// Help page module
class HelpPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initLinkOnClick();
    }

    initLinkOnClick() {
        const element = document.querySelector('.js-window-history');
        if (element) {
            element.addEventListener('click', () => {
                window.history.go(-1);
                return false;
            });
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-help-page");
    if (el) new HelpPage(el);
});