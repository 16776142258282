// Components
import dom from "@scripts/helpers/dom";
import Overlay from "@scripts/components/overlay";

// Delegation page module
class DelegationPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        
        dom.inputs.focusFirst();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-delegation-selection-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                // Allows form submission some time to be cancelled
                // due to validation
                setTimeout(() => {
                    var validationResult = (form.getAttribute("data-validated") || "").toLowerCase();

                    if (validationResult === 'true') {
                        this.formOverlay.show();
                    }
                });
            });
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-delegation-page");
    if (el) new DelegationPage(el);
});