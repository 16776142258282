// Components
import Overlay from "@scripts/components/overlay";

class ApproverConfirmConsentPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-delegation-selection-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                this.formOverlay.show();
            });
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-approver-confirm-consent-page");
    if (el) new ApproverConfirmConsentPage(el);
});