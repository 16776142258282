/*
 * Additional jQuery validations for custom data annotations
 */

import $ from "jquery";
import "jquery-validation-unobtrusive";

// By default validator ignores hidden fields.
// change the setting here to ignore disabled elements only
$.validator.setDefaults({ ignore: ":disabled" });

// -- Credit card input component integration with jQuery unobtrusive validation
$("input[data-val-creditcard]").each(function(i, input) {
    $(input).on("validated", function(el, elm, isValid) {
        const maskedInput = $(elm).siblings("[data-creditcard-mask]");

        if (isValid) {
            maskedInput.removeClass("input-validation-error");
        } else {
            maskedInput.addClass("input-validation-error");
        }
    });
});

// -- Credit card security code
$.validator.unobtrusive.adapters.add("ccsecuritycode", [], function (options) {
    options.rules["ccsecuritycode"] = true;
    options.messages["ccsecuritycode"] = options.message;
});

$.validator.addMethod("ccsecuritycode", function(value, element, params) {
    // -- Piggy backs off the "cc-card-code-input" component, which dynamically
    //    sets the maxlength property based on credit card type.
    const maxLength = element.maxLength || 0;
    const regex = new RegExp("^\\d{" + maxLength + "}$");

    let match;
    if (this.optional(element)) {
        return true;
    }

    match = new RegExp(regex).exec(value);
    return (match && (match.index === 0) && (match[0].length === value.length));
});

// -- Credit card expiration date
$.validator.unobtrusive.adapters.add("ccexpiration", [], function (options) {
    options.rules["ccexpiration"] = true;
    options.messages["ccexpiration"] = options.message;
});

$.validator.addMethod("ccexpiration", function(value, element, params) {
    const expirationParts = value.split('/');

    if (expirationParts.length !== 2) {
        return false;
    }

    const month = parseInt(expirationParts[0]);
    const year = parseInt(expirationParts[1]);

    if (isNaN(month) || isNaN(year)) {
        return false;
    }

    const now = new Date();
    const currentYear = now.getFullYear() - 2000;
    const currentMonth = now.getMonth() + 1;

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
        return false;
    }

    return true;
});

// -- Regular expression support flags [server-side not implemented]
//    (based off original regex implementation by jQuery validation)
$.validator.unobtrusive.adapters.add("regexwithflags", ["pattern", "flags"], function (options) {
    options.messages["regexwithflags"] = options.message;
    options.rules["regexwithflags"] = options.params;
});

$.validator.addMethod("regexwithflags", function (value, element, params) {
    var match;
    if (this.optional(element)) {
        return true;
    }

    match = new RegExp(params.pattern, params.flags).exec(value);
    return (match && (match.index === 0) && (match[0].length === value.length));
});