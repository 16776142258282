import extend from "@scripts/helpers/extend";

/*
 * Page Title Component -
 *
 * @param el:       An element for the text
 * @param options:  Configuration options
 */

function getPageTitleFromEl(key, el) {
    var attr = el.getAttribute("data-" + key);

    if (!attr) {
        console.warn("[Invalid title key '" + key + "']");
    }

    return attr || "";
}

class PageTitle {
    constructor(el, options) {
        // Sanity check
        if (!el) {
            throw "Invalid mounting element";
        }

        this.el = el;
        this.options = extend({}, PageTitle.defaults, options);
        this.init();
    }

    init() {
    }

    // Public Methods
    setTitle(key) {
        var title = getPageTitleFromEl(key, this.el);
        this.el.innerText = title;
    }
}

/*
 * Default options -
 *
 */
PageTitle.defaults = {
};

export default PageTitle;