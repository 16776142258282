// Components
import Overlay from "@scripts/components/overlay";
import WebPlugin from "@scripts/components/web-plugin";
import PageTitle from "@scripts/components/page-title";
import dataService from "@scripts/pages/cardFunding/services/cardFundingDataService";

// Card funding page module
class CardFundingPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        this.initTitles();
        this.initWebPlugin();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });
        this.formOverlay.show();
    }

    initTitles() {
        const pageTitleEl = document.querySelector(".js-page-title");
        this.pageTitle = new PageTitle(pageTitleEl);

        const bodyTitleEl = document.querySelector(".js-body-title");
        this.bodyTitle = new PageTitle(bodyTitleEl);

        console.log('card-view::page-|-body-title-components::init-titles');
    }

    initWebPlugin() {
        const webPluginEl = document.querySelector(".js-web-plugin");

        this.webPlugin = new WebPlugin(webPluginEl, {
            url: webPluginEl.getAttribute("data-plugin-url")
        });

        // Web plugin page load
        this.webPlugin.el.addEventListener("onPluginLoad", (data) => {
            if (this.webPlugin.invalidSession === true) {
                return;
            }

            // Updates the titles/headers dynamically based
            // on what page the web plugin is currently in.
            this.pageTitle.setTitle(data.detail.content.page);
            this.bodyTitle.setTitle(data.detail.content.page);

            console.log(`card-view::page-title<${data.detail.content.page}>::on-plugin-loaded`);
            console.log(`card-view::body-title<${data.detail.content.page}>::on-plugin-loaded`);

            this.formOverlay.hide();

            window.scrollTo(0, 0);

            console.log('card-view::hide-overlay::on-plugin-loaded');
        });

        // Funding confirmation cancellation
        this.webPlugin.el.addEventListener("onFundingCanceled", (data) => {
            console.log('card-view::funding-cancelled');
            
            this.formOverlay.show();
            console.log('card-view::show-overlay::funding-cancelled');

            window.location = "/funding";
        });

        // Funding Errors
        this.webPlugin.el.addEventListener("onTerminalFailure", (data) => {
            const error = data.detail.error || "0";
            console.log(`card-view::error<${error}>::terminal-failure`);

            this.formOverlay.show();
            console.log('card-view::show-overlay::terminal-failure');
            
            window.location = "/funding/card/error?error=" + error;
        });

        this.webPlugin.el.addEventListener("onTerminalFailureAcknowledged", (data) => {
            console.log('card-view::terminal-failure-ack');

            this.formOverlay.show();
            window.location = "/funding";
        });

        this.webPlugin.el.addEventListener("onOverallVerificationAttemptsExceeded", (data) => {
            console.log('card-view::overall-attempts-exceeded');

            this.formOverlay.show();
            console.log('card-view::show-overlay::overall-attempts-exceeded');

            window.location = "/funding/verification-exceeded";
        });

        // Funding FDT generation / confirmation
        this.webPlugin.el.addEventListener("onFDTGenerationSuccess", (data) => {
            console.log('card-view::fdt-success');
            const fundingToken = data.detail.funding_token;
            
            this.formOverlay.show();

            let timeout = setTimeout(() => {
                window.location.href = "/funding/card/error";
            }, 30000);

            dataService
                .processFundingToken(fundingToken)
                .then(() => {
                    clearTimeout(timeout);
                    window.location.href = "/funding/card/claimed";
                })
                .catch((error) => {

                    console.log('card-view::error::process-token');

                    clearTimeout(timeout);
                    const hasLocation = error && error.location && error.location.length > 0;
                    window.location.href = hasLocation ? error.location : "/funding/card/error";
                });
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-card-funding-page");
    if (el) {
        new CardFundingPage(el);
        console.log('card-view::init-js-components::dom-content-loaded');
    }
});