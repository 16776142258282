/*
 * Document Embedding Helpers
 *
 */

function deobfuscateStringValue(stringValue) {
    var base64Value = stringValue.slice(1);

    return window.atob(base64Value);
}

module.exports = {
    deobfuscateStringValue
};