// Components
import Overlay from "@scripts/components/overlay";
import WebPlugin from "@scripts/components/web-plugin";
import PageTitle from "@scripts/components/page-title";
import dataService from "@scripts/pages/payPalFunding/services/payPalFundingDataService";

// PayPal funding page module
class PayPalFundingPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        this.initTitles();
        this.initWebPlugin();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });
        this.formOverlay.show();
    }

    initTitles() {
        const pageTitleEl = document.querySelector(".js-page-title");
        this.pageTitle = new PageTitle(pageTitleEl);

        const bodyTitleEl = document.querySelector(".js-body-title");
        this.bodyTitle = new PageTitle(bodyTitleEl);
    }

    initWebPlugin() {
        const webPluginEl = document.querySelector(".js-web-plugin");

        this.webPlugin = new WebPlugin(webPluginEl, {
            url: webPluginEl.getAttribute("data-plugin-url")
        });

        // Web plugin page load
        this.webPlugin.el.addEventListener("onPluginLoad", (data) => {
            if (this.webPlugin.invalidSession === true) {
                return;
            }

            // Updates the titles/headers dynamically based
            // on what page the web plugin is currently in.
            this.pageTitle.setTitle(data.detail.content.page);
            this.bodyTitle.setTitle(data.detail.content.page);

            this.formOverlay.hide();

            window.scrollTo(0, 0);
        });

        // Funding confirmation cancellation
        this.webPlugin.el.addEventListener("onFundingCanceled", (data) => {
            this.formOverlay.show();
            window.location = "/funding";
        });

        // Funding Errors
        this.webPlugin.el.addEventListener("onTerminalFailure", (data) => {
            const error = data.detail.error || "0";
            this.formOverlay.show();
            window.location = "/funding/paypal/error?error=" + error;
        });

        this.webPlugin.el.addEventListener("onTerminalFailureAcknowledged", (data) => {
            this.formOverlay.show();
            window.location = "/funding";
        });

        this.webPlugin.el.addEventListener("onOverallVerificationAttemptsExceeded", (data) => {
            this.formOverlay.show();
            window.location = "/funding/verification-exceeded";
        });
        
        // Funding FDT generation / confirmation
        this.webPlugin.el.addEventListener("onFDTGenerationSuccess", (data) => {
            var fundingToken = data.detail.funding_token;
            
            this.formOverlay.show();

            let timeout = setTimeout(() => {
                window.location.href = "/funding/paypal/error";
            }, 30000);

            dataService
                .processFundingToken(fundingToken)
                .then(() => {
                    clearTimeout(timeout);
                    window.location.href = "/funding/paypal/claimed";
                })
                .catch((error) => {
                    clearTimeout(timeout);
                    const hasLocation = error && error.location && error.location.length > 0;
                    window.location.href = hasLocation ? error.location : "/funding/paypal/error";
                });
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-paypal-funding-page");
    if (el) new PayPalFundingPage(el);
});