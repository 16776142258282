// Components
import Overlay from "@scripts/components/overlay";
import TogglePanel from "@scripts/components/toggle-panel";

// Disclaimer page module
class DisclaimerPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initTogglePanels();
        this.initOverlay();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-disclaimer-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                this.formOverlay.show();
            });
        }
    }

    initTogglePanels() {
        const togglePanelElms = document.querySelectorAll(".js-toggle-panel");
        for (var i = 0; i < togglePanelElms.length; i++) {
            new TogglePanel(togglePanelElms[i]);
        }
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-disclaimer-page");
    if (el) new DisclaimerPage(el);
});