import extend from "@scripts/helpers/extend";
import dom from "@scripts/helpers/dom";

function initOverlay(target, options) {
    let overlay = document.createElement("div");
    overlay.setAttribute("class", "overlay overlay--fixed");
    overlay.innerHTML = `<div class="${options.overlay}"></div>`;
    target.parentNode.insertBefore(overlay, target.nextSibling);
    return overlay;
}

/**
 * Overlay component - Displays a [loading] overlay
 * 
 * @param el:       The html [input] element
 * @param options:  Customized options for styling/behavior
 */
class Overlay {
    constructor(options) {
        this.options = extend({}, Overlay.defaults, options);
        this.init();
    }

    init() {
        let target;

        if (typeof this.options.target === "object") {
            target = this.options.target;
        } else if (typeof this.options.target === "string") {
            if (!this.options.target || !(target = document.querySelector(this.options.target))) {
                console.warn("Cannot find overlay target '" + this.options.target + "'");
                return;
            }
        } else {
            console.warn("Unknown 'target' option.");
            return;
        }

        this.target = target;
        this.overlay = initOverlay(this.target, this.options);
    }

    show() {
        dom.css.addClass(this.overlay, this.options.activeClass);
        console.log(`overlay::add-class<${this.options.activeClass}>::show`);
    }

    hide() {
        dom.css.removeClass(this.overlay, this.options.activeClass);
    }
}

/* -- Default options for component -- */
Overlay.defaults = {
    target: "",
    overlay: "overlay__spinner",
    activeClass: "overlay--active"
}

export default Overlay;