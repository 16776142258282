/*
 * Extends jQuery unobtrusive validation to emit validation events
 */

import $ from "jquery";
import "jquery-validation-unobtrusive";

$.fn.addValidationEvents = function() {
    return this.each(function() {
        const form = $(this);
        const validator = form.data('validator');

        // Process if unobtrusive validator attached
        if (!validator) {
            return;
        }

        // Already initialized
        if (form.data('validator-ext-events') === true) {
            return;
        }
        
        // Injects custom form validation
        const formValidator = validator.form;
        validator.form = function() {
            const result = formValidator.apply(this, arguments);

            // Custom data-property that can be used by other services
            this.currentForm.setAttribute("data-validated", result.toString().toLowerCase());

            // Triggers the custom event
            $(form).trigger("validated", [this.currentForm, result]);
            
            return result;
        };

        // Injects custom element validation
        const elementValidator = validator.element;
        validator.element = function(element) {
            const result = elementValidator.apply(this, arguments);

            // Triggers the custom event
            $(element).trigger("validated", [element, result]);

            return result;
        };

        // Automatically trigger each element's validation event
        // upon the entire form being validated.
        form.on("validated", function(e, el) {
            const $el = $(el);
            const formValidator = $el.data("validator");
            const validationElements = $el.find('[data-val=true]');

            validationElements.each(function(i, element) {
                const name = element.name || "";
                const isValid = typeof(formValidator.invalid[name]) === "undefined";

                $(element).trigger("validated", [element, isValid]);
            });
        });

        // On reset
        form.on("reset", function(e) {
            const $form = $(e.target);

            //reset jQuery Validate's internals
            $form.validate().resetForm();

            //reset unobtrusive validation summary, if it exists
            $form.find("[data-valmsg-summary=true]")
                .removeClass("validation-summary-errors")
                .addClass("validation-summary-valid")
                .find("ul").empty();

            //reset unobtrusive field level, if it exists
            $form.find("[data-valmsg-replace]")
                .removeClass("field-validation-error")
                .addClass("field-validation-valid")
                .empty();

            //reset unobtrusive field group level, if it exists
            $form.find(".form__group--invalid").removeClass("form__group--invalid");

            //reset custom validators
            $form.find(".input-validation-error").removeClass("input-validation-error");
        });

        // Initialized
        form.data('validator-ext-events', true);
    });
};

// Startup - 
// Must use jQuery's function to ensure initialization 
// is queued after jQuery unobtrusive validation's.
$(function() {
    $("form").addValidationEvents();
});