// Components
import Overlay from "@scripts/components/overlay";

// -- TODO: Extract generic radio-list component from funding-destinations-list and refactor usages
import CancelPaymentList from "@scripts/components/funding-destinations-list";

// Funding destinations page module
class CancelPaymentPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initCancelPaymentOptionsList();
        this.initCancelPaymentOptionsForm();
    }

    initCancelPaymentOptionsList() {
        const listEl = document.querySelector(".js-cancel-payment-list");
        this.cancelPaymentList = new CancelPaymentList(listEl);
    }

    initCancelPaymentOptionsForm() {
        const formOverlay = new Overlay({ target: this.el });
        const form = document.querySelector(".js-cancel-payment-form");

        form.addEventListener("submit", (e) => {
            var isValid = this.cancelPaymentList.validate();

            if (!isValid) {
                e.preventDefault();
                return false;
            }

            formOverlay.show();
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-cancel-payment-page");
    if (el) new CancelPaymentPage(el);
});