import extend from "@scripts/helpers/extend";
import dom from "@scripts/helpers/dom";

/*
 * Web Plugin Component -
 *
 * @param el:       An input element
 * @param options:  Configuration options
 */

// Helpers
function initMountingFrame(scope, el) {

    console.log(`plugin::options<${JSON.stringify(scope.options)}>::init-mounting-frame`);
    
    const frameElement = document.createElement("iframe");
    frameElement.setAttribute("class", scope.options.cssName);
    frameElement.setAttribute("src", scope.options.url);
    frameElement.setAttribute("scrolling", "no");
    el.appendChild(frameElement);

    return frameElement;
}

function addEventListeners(scope, el) {
    window.addEventListener("message", onMessageReceived.bind(scope));
    el.addEventListener("onPluginLoad", onPluginLoad.bind(scope));
    el.addEventListener("onPluginResize", onPluginResize.bind(scope));
}

// Events
function onPluginResize(e) {
    var content = e.detail.content;
    var frameMinHeight = parseInt(this.el.style.height, 10);

    if (!!content &&
        !!content.height && 
          content.height !== frameMinHeight) {
        this.el.style.height = content.height + 'px';
    }
}

function onPluginLoad(data) {
    // Global expired / error handling
    switch (data.detail.content.page) {
        case "SessionError":
            this.invalidSession = true;
            window.location = "/session/error";
            break;
        case "SessionExpired":
            this.invalidSession = true;
            window.location = "/session/expired";
            break;
        case "UnauthorizedError":
            this.invalidSession = true;
            window.location = "/session/unauthorized";
            break;
    }
}

function onMessageReceived(e) {
    if (e.origin !== 'TODO: Origin checking') {
        // throw;
    }

    var eventData = {
        'event_name': 'ParsingError',
        'raw_event': e.data
    };
    
    try {
        eventData = JSON.parse(e.data);
    } catch (err) {
        // silence is golden
        console.warn(`plugin::JSON-parsing::error<${err}>::on-message`);
    }

    dom.events.triggerEvent(this.el, "on" + eventData.event_name, eventData);
    console.log(`plugin::event<${eventData.event_name}>::on-message`);
}

class WebPlugin {
    constructor(el, options) {
        // Sanity check
        if (!el) {
            console.warn('plugin::invalid-el');
            throw "Invalid mounting element";
        }

        this.el = el;
        this.options = extend({}, WebPlugin.defaults, options);
        this.init();
    }

    init() {
        // iFrame
        this.frameEl = initMountingFrame(this, this.el);
        console.log('plugin::mount-iframe::init');
        
        // Attach events
        addEventListeners(this, this.el);
        console.log('plugin::add-event-listeners::init');
    }
}

/*
 * Default options -
 *
 * url: string - Url of the web plugin to mount
 */
WebPlugin.defaults = {
    url: "",
    cssName: "web-plugin__frame"
};

export default WebPlugin;