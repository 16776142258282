// Components
import Overlay from "@scripts/components/overlay";
import { deobfuscateStringValue } from "@scripts/helpers/documentEmbedding";

// Document Review page module
class DocumentReviewPage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        this.initAdobeDc();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-document-review-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                this.formOverlay.show();
            });
        }
    }

    initAdobeDc() {
        const adobeDcElement = document.getElementById("adobe-dc-view");

        if (adobeDcElement) {
            const dl = deobfuscateStringValue(adobeDcElement.dataset.dl);
            const dak = deobfuscateStringValue(adobeDcElement.dataset.dak);

            document.addEventListener("adobe_dc_view_sdk.ready", function () {
                const adobeDCView = new AdobeDC.View({ clientId: dak, divId: "adobe-dc-view" });
                adobeDCView.previewFile(
                    {
                        content: { location: { url: dl } },
                        metaData: { fileName: adobeDcElement.dataset.dfn }
                    },
                    {
                        embedMode: adobeDcElement.dataset.dem,
                        showDownloadPDF: true,
                        showThumbnails: false,
                        showBookmarks: false,
                        showAnnotationTools: false,
                        showPrintPDF: true
                    }
                );
            });
        }
    }

}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-document-review-page");
    if (el) new DocumentReviewPage(el);
});