import extend from "@scripts/helpers/extend";

function isMobileBreakpoint(breakpoint) {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth;
    return windowWidth <= breakpoint;
}

class PrivacyPolicyPopup {
    constructor(el, options) {
        // Sanity check
        if (!el) {
            throw "Invalid mounting element";
        }

        this.el = el;
        this.options = extend({}, PrivacyPolicyPopup.defaults, options);
        this.init();
    }

    init() {
        this.el.addEventListener("click", (e) => {
            // If mobile, we let the hyperlink through
            if (isMobileBreakpoint(this.options.breakpoint)) {
                return true;
            }

            // Desktop, popup a new window
            e.preventDefault();

            let windowOptions = `menubar=${this.options.menubar},resizable=${this.options.resizable},scrollbars=${this.options.scrollbars},width=${this.options.width},height=${this.options.height}`;
            window.open(this.el.href, '_blank', windowOptions);

            return false;
        });
    }
}

/*
 * Default options -
 *
 */
PrivacyPolicyPopup.defaults = {
    width: 410,
    height: 500,
    resizable: 1,
    scrollbars: 1,
    menubar: 0,
    breakpoint: 767 
};

// Startup (for global link in footer)
document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector(".js-privacy-policy");
    if (el) new PrivacyPolicyPopup(el);
});

// To use the module in other pages/components
export default PrivacyPolicyPopup;