import $ from "jquery";

/*
 * PayPal Funding Data Service -
 * Wrapper for paypal funding data calls
 */
function executeCall(method, url, payload) {
    var csrfToken = $("[name='__RequestVerificationToken']").val();

    if (typeof (payload) === "object") {
        payload["__RequestVerificationToken"] = csrfToken;
    }

    var executionPromise = new Promise(
        function (resolve, reject) {
            $.ajax({
                type: method,
                url: url,
                data: payload,
                dataType: 'json',
                contentType: 'application/x-www-form-urlencoded; charset=utf-8',
                success: function (data) {
                    return resolve(data);
                },
                error: function (jqXhr, textStatus, errorThrown) {
                    var data = JSON.parse(jqXhr.responseText);
                    return reject(data);
                }
            });
        }
    );

    return executionPromise;
}

class PayPalFundingDataService {
    constructor() {
    }

    init() {
    }

    // Public Methods
    processFundingToken(fundingToken) {
        return executeCall("POST", "/funding/paypal/process", { fundingToken: fundingToken });
    }
}

const dataService = new PayPalFundingDataService();
export default dataService;