import extend from "@scripts/helpers/extend";
import dom from "@scripts/helpers/dom";

/*
 * Value Format Input Component -
 *
 * @param el:       An input element
 * @param options:  Configuration options
 */
const translations = {
    L: { pattern: "^[a-zA-Z]$", placeholder: "_" },
    A: { pattern: "^[a-zA-Z0-9]$", placeholder: "_" },
    0: { pattern: "^[0-9]$", placeholder: "#" }
};

// Helpers
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function buildFormatMap(format) {
    if (!format) {
        return "";
    }

    const escapeCharacterMap = {
        pattern: /^\\$/,
        placeholder: "\\",
        isLiteral: true
    };

    let formatMap = [],
        previousWasEscape = false;

    for (let i = 0; i < format.length; i++) {
        const c = format.charAt(i);

        // Current letter is an escape literal
        if (c === '\\') {
            // Previous character was an escape literal
            // so we should treat the escape as a literal
            // and match the '\'
            if (previousWasEscape) {
                formatMap.push(escapeCharacterMap);
            }

            // If previous character was an escape literal,
            // current escape literal is being escaped so reset
            // for the next letter.
            previousWasEscape = !previousWasEscape;
            continue;
        }

        // Current letter is a mask character
        if (translations[c]) {
            // If mask character escaped, append the character,
            // Otherwise append the character's regex
            const patternValue = previousWasEscape
                ? "^" + c + "$"
                : translations[c].pattern;

            const placeholderValue = previousWasEscape
                ? c
                : translations[c].placeholder;

            formatMap.push({
                pattern: patternValue,
                placeholder: placeholderValue,
                isLiteral: previousWasEscape
            });
        }
        else {
            // Previous character was an escape literal
            // so we should treat the escape as a literal
            // and match the '\'
            if (previousWasEscape) {
                formatMap.push(escapeCharacterMap);
            }

            // Finally if not an escape or mask character,
            // treat the character as a literal.
            formatMap.push({
                pattern: "^" + escapeRegExp(c) + "$",
                placeholder: c,
                isLiteral: true
            });
        }

        // Current char was not an escape literal
        previousWasEscape = false;
    }

    if (previousWasEscape) {
        formatMap.push(escapeCharacterMap);
    }

    return formatMap;
}

function cleanFormat(formatMap, value) {
    let result = "";

    // We want to clean the provided value of any literal characters.
    // At the same time, try to greedy match as many characters as
    // possible against the value format.  
    // Loop through each character of the provided value.
    for (let i = 0, valueLen = value.length; i < valueLen; i++) {
        const char = value.charAt(i);

        // Try to greedy match that against the next matching non-literal character
        for (let j = i, formatMapLen = formatMap.length; j < formatMapLen; j++) {
            const valueFormat = formatMap[j],
                valueRegex = new RegExp(valueFormat.pattern, "i");

            // If the current character in the format is a literal,
            // test that the current character in the provided value
            // matches that literal.  If it does, we can move on to
            // the next character in the provided value
            if (valueFormat.isLiteral) {
                if (valueRegex.test(char)) {
                    break;
                }
            } else {
                // If the current character is not a literal, then
                // it must match the value format in order to be included
                // in the cleaned result.
                if (valueRegex.test(char)) {
                    result += char;
                } else {
                    // If current character does not match the,
                    // value format, remove it from the test and
                    // adjust indices
                    if (i > 0) {
                        value = value.substr(0, i) + value.substr(i + 1);
                        i--;
                        valueLen--;
                    }
                }

                // No matter what, we break from the greedy match against
                // the value format and move on to the next character
                break;
            }
        }
    }

    return result;
}

function formatValue(formatMap, value) {
    let result = "",
        charIndex = 0;

    // Loop through the entire format map until
    // we exhaust the length of the current value
    for (let i = 0, len = formatMap.length; i < len; i++) {
        const char = value.charAt(charIndex),
              valueFormat = formatMap[i],
              valueRegex = new RegExp(valueFormat.pattern, "i");

        // If current value format character is a literal
        // simply append it to the format
        if (valueFormat.isLiteral) {
            result += valueFormat.placeholder;
        }
        else {
            // If not a literal, then test to make sure
            // it matches the expected value format character
            if (valueRegex.test(char)) {
                // Found a match, append it to the formatted
                // result and move to the next character in the
                // provided value
                result += char;
                charIndex++;
            } else {
                // If it does not match, we cannot continue
                break;
            }
        }
    }

    return result;
}

function addEventListeners(scope, input) {
    input.addEventListener("keypress", onFormatValue.bind(scope));
    input.addEventListener("keydown", onFormatValueBackspace.bind(scope));
    input.addEventListener("paste", onReformatValue.bind(scope));
    input.addEventListener("input", onReformatValue.bind(scope));
}

function initPlaceholders(formatMap, input) {
    var placeholder = "";

    formatMap.forEach(m => {
        placeholder += m.placeholder;
    });

    input.placeholder = placeholder;
}

function initInputValue(formatMap, input) {
    var value = formatValue(formatMap, input.value);
    input.value = value;
}

// Events
function onFormatValue(e) {
    const input = e.target;
    const which = e.which || e.keyCode;
    const char = String.fromCharCode(which);
    const formatMap = this.formatMap;

    // If textbox has selected text do not restrict
    // (restriction check will be made on following type)
    if (dom.inputs.hasSelectedText(input)) {
        return;
    }

    // If already prevented (invalid character, max length reached) return
    if (e.defaultPrevented) {
        return;
    }

    // Allow enters
    if (which === 13) {
        return;
    }

    e.preventDefault();

    let caretPosition = dom.inputs.getCaretPosition(input);
    const prevValue = input.value;

    // Find next non-literal
    while (caretPosition < prevValue.length &&
        formatMap[caretPosition].isLiteral) {
        caretPosition++;
    }

    // Concatenate the typed character at the position typed at
    let value = (input.value.substr(0, caretPosition) + char + input.value.substr(caretPosition));

    // Reformat the new value
    value = cleanFormat(formatMap, value);
    input.value = formatValue(formatMap, value);

    // Reset the caret position to the next position (next char or next non literal)
    let nextPosition = caretPosition;
    
    // Only change the cursor position if the value changed
    if (prevValue !== input.value) {
        nextPosition++;

        while (nextPosition < input.value.length &&
               formatMap[nextPosition].isLiteral) {
            nextPosition++;

            if (input.value.charAt(nextPosition) === char) {
                nextPosition++;
                break;
            }
        }

        // If originally typed against a literal,
        // the index will be off by 1.  Increase to move
        // it into the correct position
        if (nextPosition < formatMap.length && formatMap[caretPosition].isLiteral) {
            nextPosition++;
        }
    }

    dom.inputs.setCaretPosition(input, nextPosition);

    return dom.events.triggerEvent(input, "change");
}

function onReformatValue(e) {
    const input = e.target,
        formatMap = this.formatMap;

    let value = input.value;

    // Reformat the new value
    value = cleanFormat(formatMap, value);
    input.value = formatValue(formatMap, value);

    return dom.events.triggerEvent(input, "change");
}

function onFormatValueBackspace(e) {
    const input = e.target;
    const keyCode = e.which || e.keyCode;
    const isBackspace = keyCode === 8;
    const isDelete = keyCode === 46;
    const formatMap = this.formatMap;

    // if not backspace or delete skip check
    if (!isBackspace && !isDelete) {
        return;
    }

    // If textbox has selected text allow event to bubble
    if (dom.inputs.hasSelectedText(input)) {
        return;
    }

    // If already prevented (invalid character, max length reached) return
    if (e.defaultPrevented) {
        return;
    }

    // Stop backspace/delete from bubbling
    e.preventDefault();

    let caretPosition = dom.inputs.getCaretPosition(input),
        characterPosition = caretPosition,
        prevValue = input.value,
        value = prevValue;

    // Backspace was clicked, find previous non-literal
    if (isBackspace && caretPosition > 0) {
        characterPosition--;

        while (characterPosition > 0 &&
            formatMap[characterPosition].isLiteral) {
            characterPosition--;
        }
        
        // Remove the character + any literals it came across
        value = prevValue.substr(0, characterPosition) + prevValue.substr(caretPosition);
    }

    // Delete was clicked, find next non-literal
    if (isDelete && caretPosition < prevValue.length) {
        characterPosition++;
        
        while (characterPosition < prevValue.length &&
            formatMap[characterPosition].isLiteral) {
            characterPosition++;
        }

        // Remove the character + any literals it came across
        value = prevValue.substr(0, caretPosition) + prevValue.substr(characterPosition);
    }

    // No deletions occurred
    if (value === prevValue) {
        return;
    }

    // Reformat the new value
    value = cleanFormat(formatMap, value);
    input.value = formatValue(formatMap, value);

    // Reset the caret position
    const nextCaretPosition = isBackspace ? characterPosition : caretPosition;
    dom.inputs.setCaretPosition(input, nextCaretPosition);

    return dom.events.triggerEvent(input, "change");
}

class ValueFormatInput {
    constructor(el, options) {
        // Sanity check
        if (!el || !el.tagName || el.tagName.toLowerCase() !== "input") {
            throw "Invalid element, expected type 'input'";
        }

        // No format specified
        if (!options || !options.format || !options.format.trim()) {
            return;
        }

        this.el = el;
        this.options = extend({}, ValueFormatInput.defaults, options);
        this.init();
    }

    init() {
        this.options.format = this.options.format.trim();

        // Builds the formatting map used for input validation / formatting
        this.formatMap = buildFormatMap(this.options.format);
        
        // Init placeholders
        if (this.options.placeholders) {
            initPlaceholders(this.formatMap, this.el);
        }

        // Init initial value
        if (this.options.initMaskOnStartup) {
            initInputValue(this.formatMap, this.el);
        }
        
        // Attach events
        addEventListeners(this, this.el);
    }
}

/*
 * Default options -
 *
 * format: "" - Value format to use for input formatting
 */
ValueFormatInput.defaults = {
    format: "",
    placeholders: true,
    initMaskOnStartup: false
};

export default ValueFormatInput;