/*
 * DOM Helpers -
 *
 * Common DOM helper functions (typically used instead of importing jQuery)
 */

/* -- Class [CSS] functions -- */
function hasClass(el, className) {
    return (
        // Newer browsers
        (el.classList && el.classList.contains(className)) ||
            // Legacy browsers
            !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
    );
}

function addClass(el, className) {
    if (el.classList) {
        el.classList.add(className);
    } else if (!this.hasClass(el, className)) {
        el.className += " " + className;
    }
}

function removeClass(el, className) {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
}


/* -- Input [type='text'] functions -- */
function restrictNumeric(input) {
    input.addEventListener("keypress", function restrictNumeric(e) {
        const which = e.which || e.keyCode || 0;

        // Allow for special combos
        if (e.metaKey || e.ctrlKey) {
            return true;
        }

        // Prevent spaces
        if (which === 32) {
            return e.preventDefault();
        }

        // Null fallthrough
        if (which === 0) {
            return true;
        }

        /* Allow for -
         * - backspace (8)
         * - tab (9)
         * - enter (13)
         * - shift/ctrl/alt (16/17/18)
         * - pause|break (19)
         * - caps lock (20)
         * - escape (27)
         * TODO: [Allow for home (36), l/r arrows (37/39), insert (45), delete (46)]??
         */
        if (which < 33) {
            return true;
        }

        const input = String.fromCharCode(which);

        if (!/[\d\s]/.test(input)) {
            return e.preventDefault();
        }
    });
}

function hasSelectedText(input) {
    if (input.selectionStart !== null && input.selectionStart !== input.selectionEnd) {
        return true;
    }

    if (document && document.selection) {
        let selectionText = document.selection.createRange().text || "";
        return selectionText.length > 0;
    }

    return false;
}

function getCaretPosition(input) {
    return input.selectionStart;
}

function setCaretPosition(input, position) {
    if (input.createTextRange) {
        const range = input.createTextRange();
        range.move('character', position);
        range.select();
    }
    else {
        if (input.selectionStart) {
            input.focus();
            input.setSelectionRange(position, position);
        } else {
            input.focus();
        }
    }
}

function hasFocus(input) {
    return document.activeElement === input;
}

function focusFirst() {
    var elms = document.querySelectorAll("input[type='text'], input[type='radio'], input[type='checkbox'], select, textarea");
    elms.length > 0 && elms[0].focus();
}

/* -- Events -- */
function triggerEvent(el, name, payload) {
    // IE 9/10/11 supports custom events however it
    // must be initialized via document.createEvent vs.
    // new CustomEvent(...)
    // https://caniuse.com/#feat=customevent

    let event;
    try {
        // Modern browsers
        event = new CustomEvent(name, {
            detail: payload
        });
    } catch (err) {
        console.warn(err);

        event = document.createEvent("CustomEvent");
        if (event.initCustomEvent) {
            // Web standards
            event.initCustomEvent(name, true, true, payload);
        } else {
            // Removed from Web Standards but legacy support
            event.initEvent(name, true, true, payload);
        }
    }

    return el.dispatchEvent(event);
}

module.exports = {
    css: {
        hasClass,
        addClass,
        removeClass
    },

    inputs: {
        hasFocus,
        hasSelectedText,
        getCaretPosition,
        setCaretPosition,
        restrictNumeric,
        focusFirst
    },

    events: {
        triggerEvent
    }
};