import extend from "@scripts/helpers/extend";
import dom from "@scripts/helpers/dom";

/**
 * Toggle panel component - Simple toggle panel to collapse/expand content
 * 
 * @param el:       The html [toggle-panel] element
 * @param options:  Customized options for styling/behavior
 */
class TogglePanel {
    constructor(el, options) {
        this.el = el;
        this.options = extend({}, TogglePanel.defaults, options);
        this.init();
    }

    init() {
        this.isToggled = false;
        this.toggler = this.el.querySelector(this.options.toggleSelector);
        if (this.toggler) {
            this.toggler.addEventListener("click", () => {
                this.isToggled ? this.hide() : this.show();
            });
        }
    }

    show() {
        this.isToggled = true;
        dom.css.addClass(this.el, this.options.activeClass);
    }

    hide() {
        this.isToggled = false;
        dom.css.removeClass(this.el, this.options.activeClass);
    }
}

/* -- Default options for component -- */
TogglePanel.defaults = {
    toggleSelector: ".toggle-panel__toggle",
    activeClass: "toggle-panel--expanded"
};

export default TogglePanel;