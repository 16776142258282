/*
 * Extends jQuery unobtrusive validation for the form component
 */

import $ from "jquery";

// Startup - 
// Must use jQuery's function to ensure initialization 
// is queued after jQuery unobtrusive validation's.
$(function() {
    $('[data-val=true]').on("validated", function(e, elm, isValid) {
        if (isValid) {
            $(elm).closest(".form__group").removeClass("form__group--invalid");
        } else {
            $(elm).closest(".form__group").addClass("form__group--invalid");
        }
    });
});