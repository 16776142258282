import extend from "@scripts/helpers/extend";
import dom from "@scripts/helpers/dom";
import $ from "jquery";

// Helpers
function setListError(el, options) {
    dom.css.addClass(el, options.invalidClass);
}

function clearListError(el, options) {
    dom.css.removeClass(el, options.invalidClass);
}

function clearListActive(el, options) {
    $(`.${options.optionClass}.${options.activeClass}`, el).removeClass(options.activeClass);
}

function setRadioActive(el, options) {
    $(el).closest(`.${options.optionClass}`).addClass(options.activeClass);
}

// Events
function onRadioChange(e) {
    const radio = e.target;
    if (radio.checked) {
        clearListError(this.el, this.options);

        clearListActive(this.el, this.options);
        setRadioActive(radio, this.options);
    }
}

/**
 * Funding destinations list component - Adds custom validation to the funding destinations selection screen
 * TODO: Refactor into generic "radio-list" component
 * 
 * @param el:       The html element
 * @param options:  Customized options for styling/behavior
 */
class FundingDestinationsList {
    constructor(el, options) {
        this.el = el;
        this.options = extend({}, FundingDestinationsList.defaults, options);
        this.init();
    }

    init() {
        this.radios = $("input[type='radio']", this.el);
        for (let i = 0; i < this.radios.length; i++) {
            const radio = this.radios[i];
            radio.addEventListener("change", onRadioChange.bind(this));
        }
    }

    validate() {
        var isSet = false;
        for (let i = 0; i < this.radios.length; i++) {
            if (this.radios[i].checked) {
                isSet = true;
                break;
            }
        }

        if (!isSet) {
            setListError(this.el, this.options);
            return false;
        }

        return true;
    }

    hide() {
        dom.css.removeClass(this.el, this.options.invalidClass);
    }
}

/* -- Default options for component -- */
FundingDestinationsList.defaults = {
    optionClass: "funding-destinations-list__option",
    activeClass: "funding-destinations-list__option--active",
    invalidClass: "funding-destinations-list--invalid"
}

export default FundingDestinationsList;