// Components
import dom from "@scripts/helpers/dom";
import Overlay from "@scripts/components/overlay";
import ValueFormatInput from "@scripts/components/valueformat-input";
import $ from "jquery";

// One time authorization page module
class OneTimeAuthorizationAuthorizePage {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.initOverlay();
        this.initRegexInputs();
        this.initDynamicValidation();

        dom.inputs.focusFirst();
    }

    initOverlay() {
        this.formOverlay = new Overlay({ target: this.el });

        const form = document.querySelector(".js-send-otac-form");
        if (form) {
            form.addEventListener("submit", (e) => {
                // Allows form submission some time to be cancelled
                // due to validation
                setTimeout(() => {
                    var validationResult = (form.getAttribute("data-validated") || "").toLowerCase();

                    if (validationResult === 'true') {
                        this.formOverlay.show();
                    }
                });
            });
        }
    }

    initDynamicValidation() {
        let input = document.querySelector(".js-valueformat-input");
        var format = input.getAttribute("data-valueformat");

        input.addEventListener('keyup', (e) => {
            if (input.value.length === format.length) {
                $(".icon-overlay").removeClass("icon-overlay--disabled");
            } else {
                $(".icon-overlay").addClass("icon-overlay--disabled");
            }
        });
    }

    initRegexInputs() {
        let inputs = [...document.querySelectorAll(".js-valueformat-input")];
        inputs.forEach(input => {
            var format = input.getAttribute("data-valueformat");
            new ValueFormatInput(input, {
                format: format,
                placeholders: false,
                initMaskOnStartup: false
            });
        });
    }
}

// Startup
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("js-one-time-auth-page");
    if (el) new OneTimeAuthorizationAuthorizePage(el);
});

// Modal Controls
$(document).ready(function () {
    $(".add-pop-up").click(function (e) {
        $('#ResendOtacModal').removeClass("modal--hidden");
    });
});

$(document).ready(function () {
    $(".remove-pop-up, .modal__backdrop, .modal__close").click(function (e) {
        $('#ResendOtacModal').addClass("modal--hidden");
    });
});
